.container-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.container-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.rotate-icon {
    font-size: 4rem;
    display: inline-block;
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}